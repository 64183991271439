export const colors = {
  'blue_vivid_050': '#E6F6FF',
  'blue_vivid_100': '#BAE3FF',
  'blue_vivid_200': '#7CC4FA',
  'blue_vivid_300': '#47A3F3',
  'blue_vivid_400': '#2186EB',
  'blue_vivid_500': '#0967D2',
  'blue_vivid_600': '#0552B5',
  'blue_vivid_700': '#03449E',
  'blue_vivid_800': '#01337D',
  'blue_vivid_900': '#002159',

  'cool_grey_050': '#F5F7FA',
  'cool_grey_100': '#E4E7EB',
  'cool_grey_200': '#CBD2D9',
  'cool_grey_300': '#9AA5B1',
  'cool_grey_400': '#7B8794',
  'cool_grey_500': '#616E7C',
  'cool_grey_600': '#52606D',
  'cool_grey_700': '#3E4C59',
  'cool_grey_800': '#323F4B',
  'cool_grey_900': '#1F2933',

  'cyan_vivid_050': '#E1FCF8',
  'cyan_vivid_100': '#C1FEF6',
  'cyan_vivid_200': '#92FDF2',
  'cyan_vivid_300': '#62F4EB',
  'cyan_vivid_400': '#3AE7E1',
  'cyan_vivid_500': '#1CD4D4',
  'cyan_vivid_600': '#0FB5BA',
  'cyan_vivid_700': '#099AA4',
  'cyan_vivid_800': '#07818F',
  'cyan_vivid_900': '#05606E',

  'orange_vivid_050': '#FFE8D9',
  'orange_vivid_100': '#FFD0B5',
  'orange_vivid_200': '#FFB088',
  'orange_vivid_300': '#FF9466',
  'orange_vivid_400': '#F9703E',
  'orange_vivid_500': '#F35627',
  'orange_vivid_600': '#DE3A11',
  'orange_vivid_700': '#C52707',
  'orange_vivid_800': '#AD1D07',
  'orange_vivid_900': '#841003',

  'red_vivid_050': '#FFE3E3',
  'red_vivid_100': '#FFBDBD',
  'red_vivid_200': '#FF9B9B',
  'red_vivid_300': '#F86A6A',
  'red_vivid_400': '#EF4E4E',
  'red_vivid_500': '#E12D39',
  'red_vivid_600': '#CF1124',
  'red_vivid_700': '#AB091E',
  'red_vivid_800': '#8A041A',
  'red_vivid_900': '#610316',

  'yellow_vivid_050': '#FFFBEA',
  'yellow_vivid_100': '#FFF3C4',
  'yellow_vivid_200': '#FCE588',
  'yellow_vivid_300': '#FADB5F',
  'yellow_vivid_400': '#F7C948',
  'yellow_vivid_500': '#F0B429',
  'yellow_vivid_600': '#DE911D',
  'yellow_vivid_700': '#CB6E17',
  'yellow_vivid_800': '#B44D12',
  'yellow_vivid_900': '#8D2B0B'
};
